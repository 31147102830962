import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

import { BodyCellProps } from "./BodyCell";

const tableBody = styled.tbody``;

const tableRow = styled.tr<{ hasGroupBorder?: boolean }>`
  background-color: ${COLOR.wh};
  border-bottom: ${({ hasGroupBorder }) =>
    hasGroupBorder
      ? `1px solid ${COLOR.grayScale_600}`
      : `1px solid ${COLOR.grayScale_300}`};
`;

const tableBodyCell = styled.td<BodyCellProps>`
  padding: 16px 8px 15px;
  ${setFontStyle("Body3")};
  color: ${TEXT_COLOR.black_1};
  text-align: ${({ align }) => align};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  // sticky 사용 시 첫 열의 left는 0
  left: ${({ left }) => (left === 0 || left ? `${left}px` : "auto")};

  ${({ rowSpan }) =>
    rowSpan &&
    css`
      vertical-align: middle;
      border-right: 1px solid ${COLOR.grayScale_300};
    `};

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      z-index: 1;
      background-color: ${COLOR.wh};
    `};
`;

export default {
  tableBody,
  tableRow,
  tableBodyCell,
};
