import styled, { css, FlattenSimpleInterpolation } from "styled-components";

import {
  ToolTipPositionV2,
  ToolTipTriggerTargetInfo,
} from "@sellernote/shared/src/headlessComponents/useToolTip";

import { COLOR } from "../../styles/colors";
import { setElevationStyle } from "../../styles/elevation";
import { setFontStyle } from "../../styles/typography";

const container = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: inline-block;

  > .trigger {
    display: flex;
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "help")};

    > * {
      /* trigger가 inline 요소면 정상작동 하지 않으므로 변환 */
      display: inline-block;
    }
  }
`;

const tooltipBody = styled.div<{
  tooltipBodyStyle?: FlattenSimpleInterpolation;
  isVisibleToolTipBody: boolean;
  position: ToolTipPositionV2;
  triggerTargetInfo: ToolTipTriggerTargetInfo;
  tooltipBodySize: {
    width: number | undefined;
    height: number | undefined;
  };
  /**
   * Viewport Width. 모바일용 width 계산에 사용
   */
  vw: number;
  isMobile?: boolean;
  width?: number;
}>`
  position: fixed;
  display: inline-flex;
  z-index: 999;
  justify-content: space-between;

  visibility: ${({ isVisibleToolTipBody }) =>
    isVisibleToolTipBody ? "visible" : "hidden"};
  height: auto;
  overflow: ${({ isVisibleToolTipBody }) =>
    isVisibleToolTipBody ? "visible" : "hidden"};
  color: ${COLOR.wh};
  padding: 12px;
  width: ${(props) => {
    if (props.isMobile) {
      // 모바일의 경우 고정 width
      const sideMarginTotal = 32; // 바깥 좌우 마진 16px 반영

      // 모바일의 경우 width가 길어 의도치 않은 가로 스크롤이 생길 수 있음 레이아웃을 해치지 않도록 0으로 지정
      return props.isVisibleToolTipBody ? `${props.vw - sideMarginTotal}px` : 0;
    }

    return props.width ? `${props.width}px` : "max-content";
  }};
  border-radius: 3px;
  background-color: ${COLOR.grayScale_900};
  ${setElevationStyle("level2")};

  &::before {
    content: "";
    position: absolute;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background: ${COLOR.grayScale_900};
    box-shadow: 1px -19px 30px -19px rgba(0, 0, 0, 0.05);
    z-index: -1;
    border-radius: 2px;
  }

  ${(props) => {
    if (props.isMobile) {
      /**
       * <'모바일 여부 판단'을 media query로 하지 않는 이유>
       * - React컴포넌트에서 체크하는 값(isMobile)으로 통일해 사용하기 위함
       * - JS로직에서 변수로 사용하기 위함
       */
      return css`
        top: ${props.triggerTargetInfo.top +
        (props.triggerTargetInfo.height + 6)}px;
        // 바깥 좌우 마진 16px 반영
        left: 16px;
        // 바텀시트 z-index 반영
        z-index: 104;

        &::before {
          display: none;
        }
      `;
    }

    /** 툴팁의 화살표 사이즈 */
    const ARROW_SIZE = 4;

    /** 툴팁 화살표가 왼/오른쪽에 있고 특정 방향으로 정렬되어있을때 정렬된 방향으로 부터의 거리 */
    const ARROW_DISTANCE_FROM_SIDE = 30;

    /** 툴팁과 툴팁트리거 간의 간격 */
    const DISTANCE_FROM_TRIGGER = ARROW_SIZE + 2;

    const LEFT_POSITION_WHEN_XXX_CENTER = `${
      props.triggerTargetInfo.left -
      (props.tooltipBodySize.width || 0) / 2 +
      props.triggerTargetInfo.width / 2 +
      -ARROW_SIZE
    }px`;

    const LEFT_POSITION_WHEN_XXX_LEFT = `${
      props.triggerTargetInfo.left +
      (props.triggerTargetInfo.width || 0) / 2 -
      ARROW_DISTANCE_FROM_SIDE -
      ARROW_SIZE
    }px`;

    const LEFT_POSITION_WHEN_XXX_RIGHT = `${
      props.triggerTargetInfo.left -
      (props.tooltipBodySize.width || 0) +
      (props.triggerTargetInfo.width || 0) / 2 +
      ARROW_DISTANCE_FROM_SIDE +
      ARROW_SIZE
    }px`;

    const LEFT_POSITION_WHEN_SIDE_RIGHT_XXX = `${
      props.triggerTargetInfo.left -
      (props.tooltipBodySize.width || 0) -
      DISTANCE_FROM_TRIGGER
    }px;`;

    const LEFT_POSITION_WHEN_SIDE_LEFT_XXX = `${
      props.triggerTargetInfo.left +
      (props.triggerTargetInfo.width || 0) +
      DISTANCE_FROM_TRIGGER
    }px`;

    const TOP_POSITION_WHEN_TOP_XXX = `${
      props.triggerTargetInfo.top +
      props.triggerTargetInfo.height +
      DISTANCE_FROM_TRIGGER
    }px`;

    const TOP_POSITION_WHEN_BOTTOM_XXX = `${
      props.triggerTargetInfo.top -
      (props.tooltipBodySize.height || 0) -
      DISTANCE_FROM_TRIGGER
    }px`;

    const TOP_POSITION_WHEN_SIDE_XXX_CENTER = `${
      props.triggerTargetInfo.top -
      (props.tooltipBodySize.height || 0) / 2 +
      props.triggerTargetInfo.height / 2
    }px`;

    const TOP_POSITION_WHEN_SIDE_XXX_TOP = `${
      props.triggerTargetInfo.top - ARROW_DISTANCE_FROM_SIDE + ARROW_SIZE / 2
    }px`;

    const TOP_POSITION_WHEN_SIDE_XXX_BOTTOM = `${
      props.triggerTargetInfo.top -
      (props.tooltipBodySize.height || 0) +
      props.triggerTargetInfo.height / 2 +
      ARROW_DISTANCE_FROM_SIDE +
      ARROW_SIZE
    }px`;

    switch (props.position) {
      case "topCenter": {
        return css`
          top: ${TOP_POSITION_WHEN_TOP_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_CENTER};

          &::before {
            content: "";
            top: -${ARROW_SIZE}px;
            left: 50%;
          }
        `;
      }
      case "topLeft": {
        return css`
          top: ${TOP_POSITION_WHEN_TOP_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_LEFT};

          &::before {
            content: "";
            top: -${ARROW_SIZE}px;
            left: 30px;
          }
        `;
      }
      case "topRight": {
        return css`
          top: ${TOP_POSITION_WHEN_TOP_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_RIGHT};

          &::before {
            content: "";
            top: -${ARROW_SIZE}px;
            right: 30px;
          }
        `;
      }
      case "bottomCenter": {
        return css`
          top: ${TOP_POSITION_WHEN_BOTTOM_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_CENTER};

          &::before {
            content: "";
            bottom: -${ARROW_SIZE}px;
            left: 50%;
          }
        `;
      }
      case "bottomLeft": {
        return css`
          top: ${TOP_POSITION_WHEN_BOTTOM_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_LEFT};

          &::before {
            content: "";
            bottom: -${ARROW_SIZE}px;
            left: 30px;
          }
        `;
      }
      case "bottomRight": {
        return css`
          top: ${TOP_POSITION_WHEN_BOTTOM_XXX};
          left: ${LEFT_POSITION_WHEN_XXX_RIGHT};

          &::before {
            content: "";
            bottom: -${ARROW_SIZE}px;
            right: 30px;
          }
        `;
      }
      case "sideRightCenter": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_CENTER};
          left: ${LEFT_POSITION_WHEN_SIDE_RIGHT_XXX};

          &::before {
            content: "";
            bottom: ${(props.tooltipBodySize.height || 0) / 2 - 6}px;
            right: -${ARROW_SIZE}px;
          }
        `;
      }
      case "sideRightTop": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_TOP};
          left: ${LEFT_POSITION_WHEN_SIDE_RIGHT_XXX};

          &::before {
            content: "";
            top: ${ARROW_DISTANCE_FROM_SIDE}px;
            right: -${ARROW_SIZE}px;
          }
        `;
      }
      case "sideRightBottom": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_BOTTOM};
          left: ${LEFT_POSITION_WHEN_SIDE_RIGHT_XXX};

          &::before {
            content: "";
            bottom: ${ARROW_DISTANCE_FROM_SIDE}px;
            right: -${ARROW_SIZE}px;
          }
        `;
      }
      case "sideLeftCenter": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_CENTER};
          left: ${LEFT_POSITION_WHEN_SIDE_LEFT_XXX};

          &::before {
            content: "";
            bottom: ${(props.tooltipBodySize.height || 0) / 2 - 6}px;
            left: -${ARROW_SIZE}px;
          }
        `;
      }
      case "sideLeftTop": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_TOP};
          left: ${LEFT_POSITION_WHEN_SIDE_LEFT_XXX};

          &::before {
            content: "";
            top: ${ARROW_DISTANCE_FROM_SIDE}px;
            left: -${ARROW_SIZE}px;
          }
        `;
      }
      case "sideLeftBottom": {
        return css`
          top: ${TOP_POSITION_WHEN_SIDE_XXX_BOTTOM};
          left: ${LEFT_POSITION_WHEN_SIDE_LEFT_XXX};

          &::before {
            content: "";
            bottom: ${ARROW_DISTANCE_FROM_SIDE}px;
            left: -${ARROW_SIZE}px;
          }
        `;
      }
    }
  }}

  > .contents {
    > .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 4px;

      > .icon {
        margin-right: 4px;
      }

      > .title {
        ${setFontStyle("SubHead2")};
      }
    }
  }

  > .close {
    margin-left: 12px;
  }

  ${({ tooltipBodyStyle }) => tooltipBodyStyle};
`;

const desc = styled.div`
  ${setFontStyle("Body3")};

  > .desc-list {
    > .item {
      display: flex;
      align-items: flex-start;
    }
  }
`;

export default {
  container,
  tooltipBody,
  desc,
};
