import styled, { css } from "styled-components";

import { COLOR, TEXT_COLOR } from "@sellernote/shared/src/sds-v2/styles/colors";
import { setFontStyle } from "@sellernote/shared/src/sds-v2/styles/typography";

import { HeaderColumnProps } from "./HeaderColumn";

const tableHeader = styled.thead``;

const tableHeaderGroup = styled.tr``;

const tableHeaderColumn = styled.th<HeaderColumnProps>`
  padding: 16px 8px 15px;
  color: ${TEXT_COLOR.black_2};
  background-color: ${COLOR.grayScale_300};
  border-bottom: 1px solid ${COLOR.grayScale_400};
  width: ${({ width }) => (width ? `${width}px` : "auto")};
  // sticky 사용 시 첫 열의 left는 0
  left: ${({ left }) => (left === 0 || left ? `${left}px` : "auto")};
  ${setFontStyle("SubHead2")};

  ${({ isSticky }) =>
    isSticky &&
    css`
      position: sticky;
      z-index: 1;
    `};
`;

const innerContainer = styled.div<HeaderColumnProps>`
  display: flex;
  justify-content: ${({ align }) => align === "right" && "flex-end"};
  text-align: ${({ align }) => align};
  gap: 4px;
  align-items: center;
`;

export default {
  tableHeader,
  tableHeaderGroup,
  tableHeaderColumn,
  innerContainer,
};
