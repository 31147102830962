import { TFunction } from "i18next";

import { Incoterms } from "@sellernote/shared/src/types/common/common";
import {
  BidProjectStatus,
  BidStatus,
} from "@sellernote/shared/src/types/forwarding/bid";
import {
  CouponKind,
  CouponStatus,
  CouponType,
} from "@sellernote/shared/src/types/forwarding/coupon";

/**
 * 쿠폰 상태를 문자열로 변환합니다.
 *
 * @param status - 쿠폰의 현재 상태
 * @param t - i18next의 번역 함수
 * @returns 쿠폰 상태에 해당하는 번역된 문자열
 */
export function toCouponStatusString({
  status,
  t,
}: {
  status: CouponStatus;
  t: TFunction;
}) {
  switch (status) {
    case "used": {
      return t("page-mypage-coupon:쿠폰_상태_사용완료");
    }
    case "applied": {
      return t("page-mypage-coupon:쿠폰_상태_적용완료");
    }
    case "expired": {
      return t("page-mypage-coupon:쿠폰_상태_기한만료");
    }
    default: {
      return "";
    }
  }
}

/**
 * 쿠폰을 적용/취소할 수 있는지 확인합니다.
 *
 * @param status - 견적 상태
 * @param incoterms - 인코텀즈 조건
 * @param projectStatus - 견적 프로젝트 상태 (선택 사항)
 * @param couponStatus - 쿠폰 상태 (선택 사항)
 * @returns 쿠폰을 적용/취소할 수 있는지 여부
 *
 * @remarks
 * 인코텀즈 조건이 "CFR", "CPT", "CIF" 중 하나인 경우 쿠폰을 적용/취소할 수 없습니다.
 */
export function canUpdateCouponToBid(
  status: BidStatus,
  incoterms: Incoterms,
  projectStatus?: BidProjectStatus,
  couponStatus?: CouponStatus
): boolean {
  // 쿠폰 정책 업데이트 - incoterms C로 시작되면 쿠폰버튼 노출 X
  const invalidIncotermsList: Incoterms[] = ["CFR", "CPT", "CIF"];
  if (invalidIncotermsList.includes(incoterms)) return false;

  if (couponStatus === "used") {
    return false;
  }

  switch (status) {
    case "waiting":
    case "waitingForExporterInfo": {
      return true;
    }

    case "inProgress": {
      const validProjectStatusList: BidProjectStatus[] = [
        "beforeContactPartner",
        "contactingPartner",
        "scheduling",
        "moving",
      ];

      if (projectStatus && validProjectStatusList.includes(projectStatus)) {
        return true;
      } else {
        return false;
      }
    }

    default: {
      return false;
    }
  }
}

/**
 * 쿠폰 타입을 문자열로 변환합니다.
 *
 * @param couponType - 쿠폰 타입
 * @returns 쿠폰 타입을 대문자로 변환한 문자열
 */
export function getCouponTypeString(couponType: CouponType) {
  return couponType ? couponType.toUpperCase() : "";
}

/**
 * 쿠폰 종류를 한국어로 변환합니다.
 *
 * @param couponKind - 쿠폰 종류
 * @param t - i18next의 번역 함수
 * @returns 쿠폰 종류에 해당하는 번역된 문자열
 */
export function convertCouponKindToKorean({
  couponKind,
  t,
}: {
  couponKind: CouponKind;
  t: TFunction;
}) {
  switch (couponKind) {
    case "oneTime":
      return t("page-mypage-coupon:쿠폰_타입_일회용");
    case "fixed":
      return t("page-mypage-coupon:쿠폰_타입_일반");
    case "programatic":
      return t("page-mypage-coupon:쿠폰_타입_자동발급");
    default:
      return "-";
  }
}
