import React, { KeyboardEvent, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { SvgIconComponent } from "@sellernote/shared/src/headlessComponents/useSvgIcon";
import {
  ToolTipPositionV2,
  ToolTipPropsV2,
} from "@sellernote/shared/src/headlessComponents/useToolTip";
import newId from "@sellernote/shared/src/utils/common/newId";
import InfoFilledIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/InfoFilledIcon";
import MagnifyingGlassIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/MagnifyingGlassIcon";
import XMarkCircleIcon from "@sellernote/shared/src/sds-v2/components/svgIcons/XMarkCircleIcon";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";

import Button from "../../button/Button";
import ExclamationTriangleIcon from "../../svgIcons/ExclamationTriangleIcon";
import Tooltip from "../../Tooltip";
import Styled from "./index.styles";

type LabelInfoPosition = "left" | "top";
type SearchIconPosition = "left" | "right";
type InputSearchSize = "default" | "small";
interface InputSearchButtonInfo {
  label: string;
  onClick: () => void;
}

interface InputSearchLabelInfo {
  label: React.ReactNode;
  position: LabelInfoPosition;
  tooltipInfo?: Pick<ToolTipPropsV2, "title" | "desc" | "width"> & {
    position?: ToolTipPositionV2;
  };
  minWidth?: string;
}

interface InputSearchProps {
  inputRef?: React.RefObject<HTMLInputElement>;
  buttonInfo?: InputSearchButtonInfo;
  className?: string;
  disabled?: boolean;
  inputValue?: string;
  inputWidth?: number;
  /**
   * focus상태를 수동으로 컨트롤하고 싶을때 사용
   */
  isFocused?: boolean;
  labelInfo?: InputSearchLabelInfo;
  onInputValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onReset: () => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
  placeholder?: React.ReactNode;
  searchIconPosition?: SearchIconPosition;
  size?: InputSearchSize;
  errorMessage?: React.ReactNode;
  leftIconInfo?: {
    Icon: SvgIconComponent | undefined;
    color: string;
  };
}

export type {
  InputSearchSize,
  LabelInfoPosition,
  SearchIconPosition,
  InputSearchLabelInfo,
  InputSearchProps,
};

export default function InputSearch({
  inputRef,
  buttonInfo,
  className,
  disabled,
  inputValue,
  inputWidth,
  isFocused,
  labelInfo,
  onInputValueChange,
  onFocus,
  onBlur,
  onReset,
  onKeyDown,
  placeholder,
  searchIconPosition = "left",
  size = "default",
  onKeyPress,
  errorMessage,
  leftIconInfo,
}: InputSearchProps) {
  const { t } = useTranslation(["sds-v2"]);

  // label id를 unique하게 사용하기 위함
  const [inputId] = useState(() => newId("input-text-field-id-"));

  const [localInputFocusState, setLocalInputFocusState] = useState(false);

  /**
   * 최종적인 focus 상태
   * - isFocused를 통해 외부에서 받은 focus상태와 local input focus상태를 종합하여 계산된 최종 상태
   */
  const finalFocusState = useMemo(() => {
    if (typeof isFocused === "boolean") return isFocused;

    return localInputFocusState;
  }, [isFocused, localInputFocusState]);

  return (
    <Styled.container
      className={`${className ? className : ""} input-search`}
      disabled={disabled}
      labelInfoPosition={labelInfo?.position}
    >
      <Styled.inputContainer size={size} labelInfo={labelInfo}>
        {labelInfo && (
          <Styled.labelWrapper
            className="label"
            disabled={disabled}
            labelInfoPosition={labelInfo?.position}
            minWidth={labelInfo.minWidth}
            hasToolTip={!!labelInfo?.tooltipInfo}
          >
            <Styled.label htmlFor={inputId} minWidth={labelInfo.minWidth}>
              {labelInfo.label}
            </Styled.label>

            {labelInfo.tooltipInfo && (
              <Tooltip
                title={labelInfo.tooltipInfo.title}
                desc={labelInfo.tooltipInfo.desc}
                width={labelInfo.tooltipInfo.width}
                position={labelInfo.tooltipInfo.position || "bottomLeft"}
              >
                <InfoFilledIcon
                  width={16}
                  height={16}
                  color={TEXT_COLOR.black_3}
                />
              </Tooltip>
            )}
          </Styled.labelWrapper>
        )}

        <div className="input-section">
          <Styled.inputWrapper
            disabled={disabled}
            inputWidth={inputWidth}
            isFocused={finalFocusState}
            searchIconPosition={searchIconPosition}
            size={size}
            hasErrorMessage={!!errorMessage}
            hasValue={!!inputValue}
          >
            <input
              ref={inputRef}
              id={inputId}
              type="text"
              value={inputValue || ""}
              onChange={onInputValueChange}
              placeholder={`${
                placeholder || t("sds-v2:InputSearch_입력_안내")
              }`}
              onFocus={() => {
                onFocus?.();

                setLocalInputFocusState(true);
              }}
              onBlur={() => {
                onBlur?.();

                setLocalInputFocusState(false);
              }}
              disabled={disabled}
              onKeyDown={onKeyDown}
              onKeyPress={onKeyPress}
              autoComplete="one-time-code"
            />

            {leftIconInfo?.Icon ? (
              <leftIconInfo.Icon
                width={16}
                height={16}
                color={
                  disabled ? TEXT_COLOR.black_disabled : leftIconInfo.color
                }
                className="left-icon"
              />
            ) : (
              <MagnifyingGlassIcon
                width={16}
                height={16}
                color={
                  disabled ? TEXT_COLOR.black_disabled : COLOR.grayScale_300
                }
                className="magnifying-glass-icon"
              />
            )}

            {!!errorMessage && !finalFocusState && (
              <ExclamationTriangleIcon
                width={16}
                height={16}
                color={COLOR.error_400}
                className="exclamation-triangle-icon"
              />
            )}

            {inputValue && !disabled && (
              <XMarkCircleIcon
                width={16}
                height={16}
                color={
                  disabled ? TEXT_COLOR.black_disabled : COLOR.grayScale_300
                }
                /**
                 * input에 focus가 되어있는 상태에서 x버튼을 누르면 blur가 먼저 발생하고
                 * 그 다음에 onClick이 발생하는데, 이를 방지하기 위해 onMouseDown을 사용해서 이벤트 전파를 막음                 *
                 * https://stackoverflow.com/questions/17769005/onclick-and-onblur-ordering-issue
                 */
                onMouseDown={(e) => e.preventDefault()}
                onClick={() => {
                  if (disabled) return;

                  onReset();
                }}
                className="x-mark-circle-icon"
              />
            )}
          </Styled.inputWrapper>

          {buttonInfo && (
            <Button
              theme={labelInfo ? "primary" : "secondary"}
              borderType={labelInfo ? "filled" : "outlined"}
              size="normal"
              label={buttonInfo.label}
              handleClick={buttonInfo.onClick}
              disabled={!inputValue || disabled}
            />
          )}
        </div>
      </Styled.inputContainer>

      {errorMessage && !finalFocusState && (
        <div className="error-message">{errorMessage}</div>
      )}
    </Styled.container>
  );
}
