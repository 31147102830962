import styled, { css } from "styled-components";

import {
  SelectButtonProps,
  SelectButtonValue,
} from "@sellernote/shared/src/headlessComponents/form/useSelectButton";
import { mobile } from "@sellernote/shared/src/styles/functions";

import { COLOR, TEXT_COLOR } from "../../../styles/colors";
import { setFontStyle } from "../../../styles/typography";

interface ContainerProps
  extends Pick<
    SelectButtonProps<SelectButtonValue>,
    | "disabled"
    | "size"
    | "selected"
    | "isSelectList"
    | "desc"
    | "badgeInfo"
    | "isLabelHidden"
  > {
  isInvalid: boolean;
}

const container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;

  > label {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    display: inline-flex;
    justify-content: flex-start;
    align-items: ${({ desc, badgeInfo }) =>
      desc || badgeInfo ? "flex-start" : "center"};

    ${mobile(css`
      align-items: flex-start;
    `)}

    > .select-icon-container {
      display: flex;
      align-items: ${({ isSelectList, desc }) =>
        isSelectList || desc ? "flex-start" : "center"};
      margin-top: ${({ isSelectList, desc }) =>
        !isSelectList && desc ? "2px" : "0"};
    }

    > .label-text-container {
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: ${({ isSelectList, isLabelHidden }) => {
        if (isLabelHidden) {
          return "0";
        }

        return isSelectList ? "16px" : "8px";
      }};

      > .badge {
        margin-bottom: 8px;
      }

      > .title-area {
        display: inline-flex;
        align-items: center;

        > .title {
          ${({ size }) => {
            return size === "default"
              ? setFontStyle("SubHead3")
              : setFontStyle("SubHead2");
          }};

          color: ${({ disabled, selected, isSelectList, isInvalid }) => {
            if (isInvalid) return TEXT_COLOR.black_1;

            if (disabled) return TEXT_COLOR.black_disabled;

            if (isSelectList)
              return selected ? COLOR.primary_600 : TEXT_COLOR.black_1;

            return selected ? TEXT_COLOR.black_2 : TEXT_COLOR.black_3;
          }};
        }

        > .tooltip {
          margin-left: 4px;
        }
      }

      > .desc {
        ${({ size, isSelectList }) => {
          if (size === "default") {
            return css`
              margin-top: 4px;
              ${setFontStyle("Body4")};
            `;
          }

          if (isSelectList) {
            return css`
              ${setFontStyle("Body3")};
              margin-top: 8px;
            `;
          }

          return css`
            margin-top: 5px;
            ${setFontStyle("Body3")};
          `;
        }}

        color: ${({ disabled, selected, isSelectList }) => {
          if (disabled) return TEXT_COLOR.black_disabled;

          if (isSelectList) return TEXT_COLOR.black_3;

          return selected ? TEXT_COLOR.black_2 : TEXT_COLOR.black_3;
        }};
      }
    }
  }
`;

const errorMessage = styled.p<{ position: "left" | "right" }>`
  margin-top: 4px;
  ${setFontStyle("Body5")};
  color: ${COLOR.error_400};

  ${({ position }) =>
    position === "right" &&
    css`
      align-self: flex-end;
    `}
`;

export default {
  container,
  errorMessage,
};
