import React from "react";
import styled, { css } from "styled-components";

import { ShipdaCurrentLanguage } from "@sellernote/shared/src/i18n/i18nForShipda";
import { COLOR } from "@sellernote/shared/src/stylesToMoveToV1/constants";
import { setFontStyle } from "@sellernote/shared/src/stylesToMoveToV1/typography";

import { InputTextBorderType } from ".";

const container = styled.div<{
  borderType: InputTextBorderType;
  width?: number;
  hasError?: React.ReactNode;
  hasPrefix?: boolean;
  disabled?: boolean;
}>`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  width: ${(props) => (props.width ? `${props.width}rem` : "100%")};

  > .label {
    ${setFontStyle("Body8")};
    color: ${COLOR.grayScale_800};
    margin-bottom: 0.125rem;

    em {
      ${setFontStyle("Caption")};
      color: ${COLOR.pointWarning};
    }

    .label-guide {
      ${setFontStyle("Caption")};
      color: ${COLOR.grayScale_700};
    }
  }

  > .input-wrapper {
    position: relative;
    width: 100%;

    > input {
      width: 100%;
      height: 2.8125rem;
      display: flex;
      justify-content: center;
      align-items: center;
      ${setFontStyle("Body7")};

      &::placeholder {
        ${setFontStyle("Body7")};
        color: ${COLOR.grayScale_400};
      }

      &::-ms-clear {
        display: none;
      }

      ${(props) => {
        switch (props.borderType) {
          case "filled": {
            return css`
              padding: ${props.hasPrefix ? "11px 8px 11px 20px" : "11px 8px"};
              background-color: ${COLOR.grayScale_100};
              border-bottom: 1px solid ${COLOR.grayScale_500};

              ${props.hasError &&
              css`
                background-color: ${COLOR.pointWarning_20};
                border-color: ${COLOR.pointWarning};
              `}

              &:focus {
                background-color: ${COLOR.secondaryDeepBlue_10};
                border-bottom: 1px solid ${COLOR.secondaryDeepBlue};
                color: ${COLOR.grayScale_800};

                ${props.hasError &&
                css`
                  background-color: ${COLOR.pointWarning_20};
                  border-color: ${COLOR.pointWarning};
                `}
              }
            `;
          }
          case "outline": {
            return css`
              padding: 0.6875rem 0.5rem;
              border: 0.0625rem solid ${COLOR.grayScale_300};
              border-radius: 2px;
              background-color: transparent;

              &:focus {
                border: 0.0625rem solid ${COLOR.secondaryDeepBlue};

                ${props.hasError &&
                css`
                  border-color: ${COLOR.pointWarning};
                `}
              }
            `;
          }
          case "radius": {
            return css`
              padding: 8px 16px;
              height: auto;
              ${setFontStyle("Body7")};
              color: ${COLOR.grayScale_400};
              border: 1px solid ${COLOR.grayScale_400};
              border-radius: 20px;
              background-color: transparent;
              appearance: searchfield;

              &::placeholder {
                color: ${COLOR.grayScale_400};
              }
              &:focus {
                color: ${COLOR.grayScale_700};
                border: 1px solid ${COLOR.primaryBlue_60};

                ${props.hasError &&
                css`
                  border-color: ${COLOR.pointWarning};
                `}
              }
            `;
          }
        }
      }}
    }

    ${(props) => {
      switch (props.borderType) {
        case "radius": {
          return css`
            .post-fix {
              top: calc(50% - 8px);
              right: 35px;
            }
          `;
        }
      }
    }}
  }

  ${(props) => {
    if (props.disabled) {
      switch (props.borderType) {
        case "outline": {
          return css`
            > .label {
              color: ${COLOR.grayScale_400};
            }

            > .input-wrapper > input {
              color: ${COLOR.grayScale_400};
              background-color: ${COLOR.grayScale_100};
            }
          `;
        }

        default: {
          return css`
            > .label {
              color: ${COLOR.grayScale_400};
            }

            > .input-wrapper > input {
              border-bottom: 1px solid ${COLOR.grayScale_200};
            }
          `;
        }
      }
    }
  }}
`;

const postFix = styled.div<{ isButton: boolean }>`
  position: absolute;
  top: calc(50% - 8px);
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  > .icon {
    &:not(:last-child) {
      margin-right: 0.25rem;
    }

    &.reset {
      cursor: pointer;
    }
  }

  ${(props) => {
    if (props.isButton) {
      return css`
        right: ${ShipdaCurrentLanguage.currentLanguage === "ko"
          ? "110px"
          : "165px"};
      `;
    }
  }}
`;

const prefix = styled.div<{ hasValue: boolean }>`
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  ${setFontStyle("Body7")};
  color: ${({ hasValue }) =>
    hasValue ? `${COLOR.grayScale_800}` : `${COLOR.grayScale_400}`};
`;

const error = styled.div`
  ${setFontStyle("Caption")};
  color: ${COLOR.pointWarning};
`;

const flagBlock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 88px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;

  > img {
    width: 24px;
  }

  > .number {
    ${setFontStyle("Body7")}
    color: ${COLOR.grayScale_700};
    margin-left: 8px;
  }
`;

const button = styled.button<{ disabled: boolean }>`
  position: absolute;
  top: calc(50% - 20px);
  right: 8px;
  height: 40px;
  background-color: ${COLOR.primaryBlue};
  border: 0;
  border-radius: 2px;
  padding: 0px 8px;
  font-size: 14px;
  color: ${COLOR.white};
  z-index: 1;
  cursor: pointer;
  min-width: 66px;

  ${(props) => {
    if (props.disabled) {
      return css`
        background-color: ${COLOR.grayScale_300};
        cursor: not-allowed;
      `;
    }
  }}
`;

export default {
  container,
  postFix,
  prefix,
  error,
  flagBlock,
  button,
};
