import { Country, Currency, Port } from "../../../types/common/common";
import {
  ApplyBidFeeData,
  ApplyBidFormData,
} from "../../../types/forwarding/adminBid";
import {
  BidProjectStatus,
  BidServiceType,
} from "../../../types/forwarding/bid";
import {
  InvoiceData,
  InvoiceDataFeeItem,
  TradingStatementElem,
  TradingStatementInvoice,
} from "../../../types/forwarding/tradingStatement";
import {
  ExchangeRate,
  InvoiceType,
  TrelloBidDetail,
  TrelloBidManagement,
  WithdrawalFormDetail,
} from "../../../types/forwarding/trello";

import {
  STORAGE_CHARGE_COMMENT,
  TRADING_STATEMENT_COMMON_COMMENT,
} from "../../../constants/forwarding/adminTradingStatement";
import { getAppTodayMidnight, isTodayOrBeforeToday } from "../../common/date";
import { getCustomRound } from "../../common/number";
import { multiplyByBigNumber } from "../../common/number";
import {
  FARECON_DOMESTIC_PARTNER_ID,
  FARECON_FOREIGN_PARTNER_ID,
  GLOBELINK_KOREA_PARTNER_ID,
  GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID,
  GLOBELINK_SHANGHAI_FCL_PARTNER_ID,
  GLOBELINKSHA_PARTNER_ID,
} from "./partner";

/**
 * 주어진 운임 항목 리스트의 총 가격을 계산합니다.
 *
 * @param feeData - 운임 항목 리스트
 * @returns 총 가격
 */
const getTotalItemPriceOfFeeData = (feeData: ApplyBidFeeData[]) => {
  const totalPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && !currentValue.isTaxExempt) {
      return accumulator + Number(currentValue.itemPrice);
    }
    return accumulator;
  }, 0);

  return totalPriceOfFeeData;
};

/**
 * 모든 운임 항목 리스트의 총 가격을 계산합니다.
 *
 * @param freight - 해상 운임 항목 리스트
 * @param domestic - 국내 운임 항목 리스트
 * @param local - 현지 운임 항목 리스트
 * @param inland - 내륙 운임 항목 리스트
 * @param other - 기타 운임 항목 리스트
 * @param tax - 세금 항목 리스트
 * @returns 모든 운임 항목의 총 가격
 */
export const getAllTotalItemPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalItemPriceOfFreightFeeData = 0;
  let totalItemPriceOfDomesticFeeData = 0;
  let totalItemPriceOfLocalFeeData = 0;
  let totalItemPriceOfInlandFeeData = 0;
  let totalItemPriceOfOtherFeeData = 0;
  let totalItemPriceOfTaxFeeData = 0;

  if (freight) {
    totalItemPriceOfFreightFeeData = getTotalItemPriceOfFeeData(freight);
  }
  if (domestic) {
    totalItemPriceOfDomesticFeeData = getTotalItemPriceOfFeeData(domestic);
  }
  if (local) {
    totalItemPriceOfLocalFeeData = getTotalItemPriceOfFeeData(local);
  }
  if (inland) {
    totalItemPriceOfInlandFeeData = getTotalItemPriceOfFeeData(inland);
  }
  if (other) {
    totalItemPriceOfOtherFeeData = getTotalItemPriceOfFeeData(other);
  }
  if (tax) {
    totalItemPriceOfTaxFeeData = getTotalItemPriceOfFeeData(tax);
  }

  if (totalItemPriceOfFreightFeeData < 0) {
    totalItemPriceOfFreightFeeData = 0;
  }
  return (
    totalItemPriceOfLocalFeeData +
    totalItemPriceOfDomesticFeeData +
    totalItemPriceOfFreightFeeData +
    totalItemPriceOfInlandFeeData +
    totalItemPriceOfOtherFeeData +
    totalItemPriceOfTaxFeeData
  );
};

/**
 * 주어진 운임 항목 리스트의 총 VAT 금액을 계산합니다.
 *
 * @param feeData - 운임 항목 리스트
 * @returns 총 VAT 금액
 */
const getTotalVatPriceOfFeeData = (feeData: TradingStatementElem[]) => {
  const totalVatPriceOfFeeData = feeData.reduce((accumulator, currentValue) => {
    if (currentValue.itemPrice && currentValue.isVAT) {
      return accumulator + Math.floor(currentValue.itemPrice * 0.1);
    }
    return accumulator;
  }, 0);

  return totalVatPriceOfFeeData;
};

/**
 * 모든 운임 항목 리스트의 총 VAT 금액을 계산합니다.
 *
 * @param freight - 해상 운임 항목 리스트
 * @param domestic - 국내 운임 항목 리스트
 * @param local - 현지 운임 항목 리스트
 * @param inland - 내륙 운임 항목 리스트
 * @param other - 기타 운임 항목 리스트
 * @param tax - 세금 항목 리스트
 * @returns 모든 운임 항목의 총 VAT 금액
 */
export const getAllTotalVatPriceOfFeeData = (
  freight: TradingStatementElem[],
  domestic: TradingStatementElem[],
  local: TradingStatementElem[],
  inland: TradingStatementElem[],
  other: TradingStatementElem[],
  tax: TradingStatementElem[]
) => {
  let totalVatPriceOfFreightFeeData = 0;
  let totalVatPriceOfDomesticFeeData = 0;
  let totalVatPriceOfLocalFeeData = 0;
  let totalVatPriceOfInlandFeeData = 0;
  let totalVatPriceOfOtherFeeData = 0;
  let totalVatPriceOfTaxFeeData = 0;

  if (freight) {
    totalVatPriceOfFreightFeeData = getTotalVatPriceOfFeeData(freight);
  }
  if (domestic) {
    totalVatPriceOfDomesticFeeData = getTotalVatPriceOfFeeData(domestic);
  }
  if (local) {
    totalVatPriceOfLocalFeeData = getTotalVatPriceOfFeeData(local);
  }
  if (inland) {
    totalVatPriceOfInlandFeeData = getTotalVatPriceOfFeeData(inland);
  }
  if (other) {
    totalVatPriceOfOtherFeeData = getTotalVatPriceOfFeeData(other);
  }
  if (tax) {
    totalVatPriceOfTaxFeeData = getTotalVatPriceOfFeeData(tax);
  }
  return (
    totalVatPriceOfLocalFeeData +
    totalVatPriceOfDomesticFeeData +
    totalVatPriceOfFreightFeeData +
    totalVatPriceOfInlandFeeData +
    totalVatPriceOfOtherFeeData +
    totalVatPriceOfTaxFeeData
  );
};

/**
 * TODO: 거래명세서가 아닌 출금액 요청에서 함수가 사용되고 있음 위치 변경이 필요함
 *
 * 주어진 환율 데이터를 사용하여 운임 항목의 단가를 계산합니다.
 *
 * @param exchangeData - 환율 데이터 리스트
 * @param feeData - 운임 항목 데이터
 * @returns 환율이 적용된 단가
 */
export const getUnitPriceByCurrencyOfFeeData = (
  exchangeData: ExchangeRate[],
  feeData: TradingStatementElem | WithdrawalFormDetail
) => {
  const currencyIndex = exchangeData.findIndex((v: ExchangeRate) => {
    return (
      v.currency === feeData.currency ||
      (v.currency === "CNH" && feeData.currency === "CNY")
    );
  });

  if (feeData.currency === "KRW") {
    return feeData.unitPrice;
  }
  if (currencyIndex >= 0) {
    const itemPrice = feeData.unitPrice * exchangeData[currencyIndex].rate;
    return itemPrice;
  }
  return feeData.unitPrice;
};

/** 이우 창고 ID, 운영/개발 동일 */
const YIWU_WAREHOUSE_ID = 13;
/** 위해 창고 ID, 운영/개발 동일 */
const WEIHAI_WAREHOUSE_ID = 16;

/**
 * RTon을 계산합니다.
 *
 * (코드와 맞지 않으나, 기존에 있던 주석을 그대로 가져옴)
 * @param {*} volume // cbm과 volume은 같은 값이다 cbm처럼 m단위로 계산된 volume이어야 함
 * @param {*} weight // ton단위여야함
 *
 * @param shipmentDetailData - 운송 상세 데이터
 * @param cbm - CBM 값
 * @param ton - 톤 값
 * @param directAirRton - 직접 입력한 RTon 값
 * @returns 계산된 RTon 값
 *
 * @remarks
 * AIR인 경우 직접 입력한 RTon 값을 반환합니다.
 *
 * TODO: 쉽다에서는 shared > calculateRTonByWarehouse를 사용하고있어 통합 필요
 */
export const calculateRTonForTradingStatement = ({
  shipmentDetailData,
  cbm,
  ton,
  directAirRton,
}: {
  shipmentDetailData: TrelloBidDetail;
  cbm: number;
  ton: number;
  directAirRton: number;
}) => {
  /** AIR인 경우 직접 입력한 rton을 리턴 거래명세서에서 초기값은 0이다.*/
  if (shipmentDetailData.freightType === "AIR") return directAirRton;

  const volume = cbm;
  let weight = ton / 1000;

  if (shipmentDetailData.wareHouse) {
    if (shipmentDetailData.wareHouse?.id === WEIHAI_WAREHOUSE_ID) {
      weight = ton / 1000;
    } else if (shipmentDetailData.wareHouse?.id === YIWU_WAREHOUSE_ID) {
      weight = ton / 500;
    } else {
      weight = ton / 250;
    }
  }

  /** 위 계산에서 무게값을 나누기 하면서 부동소숫점 문제로 소숫점이 길어짐 3자리로 반올림한다. */
  const roundedWeight = getCustomRound(weight, 3);

  const returnValue = volume > roundedWeight ? volume : roundedWeight;

  if (shipmentDetailData.serviceType !== "oceanTicket") {
    if (returnValue < 1) {
      return 1;
    }
    return returnValue;
  }
  return returnValue;
};

/**
 * 거래명세서를 사용자에게 발송할 수 있는지 확인합니다.
 *
 * @param projectStatus - 프로젝트 상태
 * @param isImport - 수입 여부
 * @returns 발송 가능 여부
 */
export const checkIfTradingStatementCanBeSendToUser = (
  projectStatus: BidProjectStatus,
  isImport: boolean
) => {
  // 수출에서는 모든 상태에서 발송이 가능함
  if (!isImport) {
    return false;
  }

  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling" ||
    projectStatus === "moving"
  ) {
    return true;
  }
  return false;
};

/**
 * 부킹 커미션 명세서를 발송할 수 있는지 확인합니다.
 *
 * @param projectStatus - 프로젝트 상태
 * @returns 발송 가능 여부
 */
export const checkIfRefundTradingStatementCanBeSend = (
  projectStatus: BidProjectStatus
) => {
  if (
    projectStatus === "beforeContactPartner" ||
    projectStatus === "contactingPartner" ||
    projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

/**
 * 거래명세서를 통관업체에 발송할 수 있는지 확인합니다.
 *
 * @param bidData - 견적 상세 데이터
 * @returns 발송 가능 여부
 */
export const checkIfTradingStatementCanBeSendToCustomsBroker = (
  bidData: TrelloBidDetail
) => {
  // 수출에서는 모든 상태에서 발송이 가능함
  if (!bidData.isImport) {
    return false;
  }

  if (bidData.projectStatus === "moving") {
    if (bidData.management?.detailStatus === null) {
      return true;
    }
    return false;
  }
  if (
    bidData.projectStatus === "beforeContactPartner" ||
    bidData.projectStatus === "contactingPartner" ||
    bidData.projectStatus === "scheduling"
  ) {
    return true;
  }
  return false;
};

/**
 * 발행된 인보이스가 있는지 확인합니다.
 *
 * @param invoice - 거래명세서 인보이스
 * @returns 발행된 인보이스 여부
 */
export const checkIsIssuedInvoices = (
  invoice: TradingStatementInvoice | undefined | null
) => {
  if (invoice) {
    if (invoice.issuedInvoices.length >= 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

/**
 * 특정 통화의 환율을 찾습니다.
 *
 * @param exchangeRateList - 환율 리스트
 * @param currency - 통화
 * @returns 특정 통화의 환율
 */
export const findExchangeRate = (
  exchangeRateList: ExchangeRate[] | undefined,
  currency: string
) => {
  const specificExchangeRate = exchangeRateList?.find((v) => {
    return v.currency === currency;
  });

  if (specificExchangeRate) {
    return specificExchangeRate.rate === 0
      ? 1
      : Number(specificExchangeRate.rate);
  } else {
    return 1;
  }
};

/**
 * 거래명세서의 도착 데이터를 가져옵니다.
 *
 * @param invoiceData - 인보이스 데이터
 * @param management - 관리 데이터
 * @returns 도착 데이터
 */
export const getTradingStatementArrivalData = (
  invoiceData: InvoiceData | undefined,
  management: TrelloBidManagement
) => {
  return invoiceData?.arrivalDate || management.fullATA || "";
};

/**
 * 거래명세서의 코멘트를 가져옵니다.
 *
 * @param invoiceType - 인보이스 타입
 * @param serviceType - 서비스 타입
 * @returns 거래명세서 코멘트
 *
 * @remarks
 * 부킹커미션(데이터는 refund) 명세서에서는 빈 문자열을 반환합니다.
 */
export const getTradingStatementComment = ({
  invoiceType,
  serviceType,
}: {
  invoiceType: InvoiceType;
  serviceType: BidServiceType;
}) => {
  /** 부킹커미션(데이터는 refund) 명세서에서는 빈스트링을 리턴 */
  if (invoiceType === "refund") return "";

  /** 일반의뢰가 아닌 경우(콘솔, 오션티켓)에만 창고보관료 코멘트 추가 */
  if (serviceType !== "general")
    return STORAGE_CHARGE_COMMENT + "\n" + TRADING_STATEMENT_COMMON_COMMENT;

  return TRADING_STATEMENT_COMMON_COMMENT;
};

/**
 * 포트 이름을 가져옵니다.
 *
 * @param portId - 포트 ID
 * @param portData - 포트 데이터 리스트
 * @returns 포트 이름
 */
export const getTradingStatementPortName = (
  portId: number,
  portData: Port[]
) => {
  return portData.find((v) => v.id === portId)?.nameEN || "-";
};

/**
 * CBM 문자열에서 CBM 인덱스를 가져옵니다.
 *
 * @param string - CBM 문자열
 * @returns CBM 인덱스
 */
const getCbmStringIndex = (string: string) => {
  return string.indexOf(" CBM");
};

/**
 * 무게 문자열에서 무게 인덱스를 가져옵니다.
 *
 * @param string - 무게 문자열
 * @returns 무게 인덱스
 */
const getKgsStringIndex = (string: string) => {
  return string.indexOf(" kgs");
};

/**
 * 슬래시 문자열에서 슬래시 인덱스를 가져옵니다.
 *
 * @param string - 슬래시 문자열
 * @returns 슬래시 인덱스
 */
const getSlashStringIndex = (string: string) => {
  return string.indexOf("/");
};

/**
 * 유니패스 또는 거래명세서의 CBM 문자열 값에서 CBM 숫자를 추출합니다.
 *
 * @param string - CBM 문자열
 * @returns CBM 숫자
 */
export const extractCbmNumberFromCbmString = (string: string) => {
  const cbmIndex = getCbmStringIndex(string);

  const cbm = Number(string.substring(0, cbmIndex));

  // Number로 변경했을 때 NaN인지 확인
  if (isNaN(cbm)) {
    return 0;
  }

  return cbm;
};

/**
 * 유니패스 또는 거래명세서의 CBM 문자열 값에서 무게 숫자를 추출합니다.
 *
 * @param string - CBM 문자열
 * @param freightType - 화물 타입
 * @returns 무게 숫자
 */
export const extractWeightNumberFromCbmString = (
  string: string,
  freightType: string
) => {
  const slashIndex =
    freightType === "AIR"
      ? getKgsStringIndex(string)
      : getSlashStringIndex(string);

  const weight =
    freightType === "AIR"
      ? Number(string.substring(0, slashIndex).replace(",", ""))
      : Number(
          string.substring(slashIndex + 2, string.length - 4).replace(",", "")
        );

  // Number로 변경했을 때 NaN인지 확인
  if (isNaN(weight)) {
    return 0;
  }

  return weight;
};

/**
 * 견적 상세 데이터에서 CBM 또는 무게를 가져옵니다.
 *
 * @param bidDetailData - 견적 상세 데이터
 * @param type - 가져올 데이터 타입 ("cbm" 또는 "weight")
 * @returns CBM 또는 무게 값
 */
export const getBidDataCbmOrWeight = (
  bidDetailData: TrelloBidDetail,
  type: "cbm" | "weight"
) => {
  if (bidDetailData.freightType === "LCL") {
    if (bidDetailData.serviceType === "consolidation") {
      return type === "cbm"
        ? bidDetailData.totalCBM
        : bidDetailData.totalWeight;
    }

    return bidDetailData.bidItems.reduce((acc, cur) => {
      return getCustomRound((acc += cur[type]), 3);
    }, 0);
  }

  // 일반적으론 유니패스 데이터에서 가지고 옴 SG에서는 유니패스 데이터가 없어 supply도 대체
  if (bidDetailData.freightType === "AIR") {
    return bidDetailData.supply;
  }

  return 0;
};

/**
 * 국가 리스트에서 특정 국가의 이름을 가져옵니다.
 *
 * @param countryList - 국가 리스트
 * @param country - 국가 이름
 * @returns 국가 이름 (영문)
 */
export const getTradingStatementCountryName = (
  countryList: Country[],
  country: string
) => {
  const countryData = countryList.find((n) => {
    return n.name === country;
  });
  if (countryData) {
    if (countryData.nameEN === "Republic of Korea") {
      return "korea";
    }
    return countryData.nameEN;
  }
  return "-";
};

/**
 * 파트너의 이름 또는 사업자 등록 번호를 가져옵니다.
 *
 * @param invoiceType - 인보이스 타입
 * @param bidDetailData - 견적 상세 데이터
 * @param type - 가져올 데이터 타입 ("BRNNumber" 또는 "name")
 * @returns 파트너의 이름 또는 사업자 등록 번호
 */
export const getPartnerNameOrBRNNumber = (
  invoiceType: InvoiceType,
  bidDetailData: TrelloBidDetail,
  type: "BRNNumber" | "name"
) => {
  if (invoiceType === "refund") {
    const domesticPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_KOREA_PARTNER_ID ||
        v.partner?.id === FARECON_DOMESTIC_PARTNER_ID
      );
    });

    const foreignPartners = bidDetailData.accountPayables.filter((v) => {
      return (
        v.partner?.id === GLOBELINK_SHANGHAI_EXPRESS_PARTNER_ID ||
        v.partner?.id === GLOBELINKSHA_PARTNER_ID ||
        v.partner?.id === GLOBELINK_SHANGHAI_FCL_PARTNER_ID ||
        v.partner?.id === FARECON_FOREIGN_PARTNER_ID
      );
    });

    // 국내와 해외 둘 다 있을 경우는 국내가 우선이다.
    // 두개 이상이 있을 때 맨 처음 파트너가 표시되어야 한다.
    if (domesticPartners.length > 0) {
      return type === "name"
        ? domesticPartners[0].partner.name
        : domesticPartners[0].partner.BRNNumber;
    }

    if (foreignPartners.length > 0) {
      return type === "name"
        ? foreignPartners[0].partner.name
        : foreignPartners[0].partner.BRNNumber;
    }
  }

  if (type === "name") {
    return bidDetailData.team.isPrivate
      ? bidDetailData.user.company
      : bidDetailData.team.company;
  }

  return bidDetailData.team.BRN || "";
};

export const REFUND_FREIGHT_FEE_ITEM: TradingStatementElem = {
  key: "bookingCommission",
  item: "BOOKING COMMISSION",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "USD",
  itemPrice: 1000,
  unitPrice: 1,
  itemUnitMeasurement: "R.TON",
  isTaxExempt: false,
};

export const WAREHOUSE_RECEIPT_FEE_ITEM: TradingStatementElem = {
  key: "warehouseReceiptFee",
  item: "창고료",
  note: "",
  isVAT: true,
  amount: 1,
  atCost: false,
  currency: "KRW",
  itemPrice: 1,
  unitPrice: 1,
  itemUnitMeasurement: "B/L",
  isTaxExempt: false,
};

/**
 * 인보이스 데이터 항목의 문자열 값을 숫자로 변환합니다.
 *
 * @param v - 인보이스 데이터 항목 리스트
 * @returns 숫자로 변환된 인보이스 데이터 항목 리스트
 */
export const changeFeeDataStringValueToNumber = (v: InvoiceDataFeeItem[]) => {
  if (v) {
    const feeData = v.map((n) => {
      return {
        ...n,
        amount: n.amount ? Number(n.amount) : 0,
        itemPrice:
          typeof n.itemPrice === "number"
            ? n.itemPrice
            : Number(n.itemPrice.replace(/,/g, "")),
        unitPrice:
          typeof n.unitPrice === "number"
            ? n.unitPrice
            : Number(n.unitPrice.replace(/,/g, "").replace(".00", "")),
        note: n.note,
      };
    });
    return feeData;
  }
  return [];
};

/**
 * 인보이스 타입을 한글로 변환합니다.
 *
 * @param invoiceType - 인보이스 타입 문자열
 * @returns 한글로 변환된 인보이스 타입 문자열
 */
export const getInvoiceTypeKR = (invoiceType: string) => {
  switch (invoiceType) {
    case "invoice":
      return "거래명세서";
    case "refund":
      return "부킹 커미션 명세서";
    case "warehouseReceipt":
      return "창고료 명세서";
    case "etcDeposit":
      return "기타입금 명세서";
    default:
      return "-";
  }
};

/**
 * 주어진 운임 항목 리스트의 총 면세 금액을 계산합니다.
 *
 * @param feeData - 운임 항목 리스트
 * @returns 총 면세 금액
 */
export const getTotalTaxExemptPriceOfFeeData = (
  feeData: TradingStatementElem[]
) => {
  const taxExemptPriceOfFeeData = feeData.reduce(
    (accumulator, currentValue) => {
      if (currentValue.isTaxExempt) {
        return accumulator + Number(currentValue.itemPrice);
      }
      return accumulator;
    },
    0
  );

  return taxExemptPriceOfFeeData;
};

/**
 * 항목 가격(itemPrice)에 기준환율을 반영해서 리턴합니다.
 *
 * @param exchangeRateList - 환율 리스트
 * @param feeData - 운임 항목 데이터
 * @returns 기준환율이 반영된 항목 가격
 */
export const getConvertedItemPriceByExchangeRate = (
  exchangeRateList: ExchangeRate[],
  feeData: TradingStatementElem
) => {
  const exchangeRate =
    exchangeRateList.find((exchange: ExchangeRate) => {
      return exchange.currency === feeData.currency;
    })?.rate || 1;

  if (feeData.currency === "KRW") {
    return feeData.itemPrice;
  }

  // 부동소숫점으로 인한 문제가 있어 getCustomRound를 사용
  return getCustomRound(
    // 운송의뢰의 단위와, 견적항목의 단위가 다를때는 수량을 참조하지 않고 비워둔다로 amount가 없을 수 있음
    feeData.unitPrice * (feeData?.amount || 0) * exchangeRate,
    0
  );
};

/**
 * 항목에 기준환율을 적용시킨 가격(itemPrice)을 적용시킵니다.
 *
 * @param feeDataList - 운임 항목 리스트
 * @param exchangeRateList - 환율 리스트
 * @returns 기준환율이 적용된 운임 항목 리스트
 */
export const applyExchangeRateToFeeDataList = ({
  feeDataList,
  exchangeRateList,
}: {
  feeDataList: TradingStatementElem[];
  exchangeRateList: ExchangeRate[];
}) => {
  return feeDataList.map((feeData) => {
    return {
      ...feeData,
      itemPrice: getConvertedItemPriceByExchangeRate(exchangeRateList, feeData),
    };
  });
};

/**
 * 기본 운임 항목 데이터를 가져옵니다.
 *
 * @param invoiceType - 인보이스 타입
 * @param invoiceData - 인보이스 데이터
 * @param feeDataType - 운임 항목 데이터 타입
 * @param tradingStatementFeeData - 거래명세서 운임 항목 데이터
 * @param exchangeRateList - 환율 리스트
 * @param shipmentDetailData - 운송 상세 데이터
 * @returns 기본 운임 항목 데이터
 */
export const getDefaultFeeData = ({
  invoiceType,
  invoiceData,
  feeDataType,
  tradingStatementFeeData,
  exchangeRateList,
  shipmentDetailData,
}: {
  invoiceType: InvoiceType;
  invoiceData: InvoiceData | undefined;
  feeDataType:
    | "freightFee"
    | "domesticFee"
    | "localFee"
    | "inlandFee"
    | "taxFee"
    | "otherFee";
  tradingStatementFeeData: {
    freightFee: TradingStatementElem[];
    domesticFee: TradingStatementElem[];
    localFee: TradingStatementElem[];
    inlandFee: TradingStatementElem[];
    otherFee: TradingStatementElem[];
    taxFee: TradingStatementElem[];
  };
  exchangeRateList: ExchangeRate[];
  shipmentDetailData: TrelloBidDetail;
}) => {
  // 인보이스 데이터가 오는 경우 string으로 된 데이터를 number로 변경해준다.
  if (invoiceData) {
    return changeFeeDataStringValueToNumber(invoiceData[feeDataType]);
  }

  const commonParams = {
    cbm: getTradingStatementInitialCbm({ shipmentDetailData, invoiceData }),
    ton: getTradingStatementInitialTon({ shipmentDetailData, invoiceData }),
    shipmentDetailData,
    // 초기에는 directAirRton을 0으로 설정
    directAirRton: 0,
  };

  if (invoiceType === "refund") {
    if (feeDataType === "freightFee") {
      // 부킹 커미션(리펀드)에서는 USD를 1000으로 계산한다.
      const refundExchangeRateList = exchangeRateList.map((exchangeRate) =>
        exchangeRate.currency === "USD"
          ? { ...exchangeRate, rate: 1000 }
          : exchangeRate
      );
      return getReCalculatedFeeData({
        feeDataList: [REFUND_FREIGHT_FEE_ITEM],
        exchangeRateList: refundExchangeRateList,
        ...commonParams,
      });
    }
    return [];
  }

  if (invoiceType === "warehouseReceipt") {
    if (feeDataType === "otherFee") {
      return getReCalculatedFeeData({
        feeDataList: [WAREHOUSE_RECEIPT_FEE_ITEM],
        exchangeRateList,
        ...commonParams,
      });
    }

    return [];
  }

  if (invoiceType === "etcDeposit") {
    return [];
  }

  // 인보이스(명세서) 데이터가 없는 경우 초기 환율과 초기 화물정보로 재계산한 FeeData를 리턴한다.
  return getReCalculatedFeeData({
    feeDataList: tradingStatementFeeData[feeDataType],
    exchangeRateList,
    ...commonParams,
  });
};

/**
 * 인보이스의 환율 리스트를 가져옵니다.
 *
 * @param invoiceType - 인보이스 타입
 * @param exchangeRateData - 환율 데이터 리스트
 * @param invoiceData - 인보이스 데이터
 * @returns 인보이스의 환율 리스트
 */
export const getInvoiceExchangeRateList = ({
  invoiceType,
  exchangeRateData,
  invoiceData,
}: {
  invoiceType: InvoiceType;
  exchangeRateData: ExchangeRate[];
  invoiceData: InvoiceData | undefined;
}) => {
  // 부킹 커미션(리펀드) 에서는 1000원 고정
  if (invoiceType === "refund") {
    return exchangeRateData.map((v) => {
      return v.currency === "USD" ? { ...v, rate: 1000 } : v;
    });
  }

  // 거래명세서 데이터가 있는 경우 수동으로 변경한 환율을 반영한다.
  if (invoiceData) {
    return exchangeRateData.map((v) => {
      return Object.keys(invoiceData.currencyJson).includes(v.currency)
        ? {
            ...v,
            rate: Number(invoiceData.currencyJson[v.currency]),
          }
        : v;
    });
  }

  return exchangeRateData;
};

/**
 * 확정견적 항목을 거래명세서용 항목으로 변경합니다.
 *
 * @param quotationFeeList - 확정견적 항목 리스트
 * @returns 거래명세서용 항목 리스트
 */
const changeQuotationFeeListForTradingStatement = (
  quotationFeeList: TradingStatementElem[]
): TradingStatementElem[] => {
  if (!quotationFeeList) return [];

  return quotationFeeList.map((feeItem) => {
    // quotationUser 데이터에서 모든 항목 데이터가 들어가지 않는 경우가 있어서 상황에 따라 항목 값들을 추가한다.
    if (feeItem.isVAT) {
      if (feeItem.atCost) {
        return {
          ...feeItem,
          isVAT: true,
          amount: 0,
          currency: "KRW",
          itemPrice: 0,
          unitPrice: 0,
          itemUnitMeasurement: "",
        };
      }

      return {
        ...feeItem,
        isVAT: true,
      };
    }

    // 실비인 경우 데이터에서 amount, itemPrice, unitPrice , currency, itemUnitMeasurement가 없어 추가
    if (feeItem.atCost) {
      return {
        ...feeItem,
        isVAT: false,
        amount: 0,
        currency: "KRW",
        itemPrice: 0,
        unitPrice: 0,
        itemUnitMeasurement: "",
      };
    }

    return {
      ...feeItem,
      isVAT: false,
    };
  });
};

/**
 * 확정견적의 항목 데이터를 거래명세서용으로 변경한 데이터를 리턴한다.
 *
 * @param shipmentDetailData - 견적 상세 데이터
 * @returns 거래명세서용 항목 데이터
 */
export const getTradingStatementFeeData = (
  shipmentDetailData: TrelloBidDetail
) => {
  const taxFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].taxFee
  );
  const otherFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].otherFee
  );
  const freightFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].freightFee
  );
  const domesticFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].domesticFee
  );
  const localFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].localFee
  );
  const inlandFee = changeQuotationFeeListForTradingStatement(
    shipmentDetailData.quotationsUser[0].inlandFee
  );

  const tradingStatementFeeData = {
    freightFee,
    domesticFee,
    localFee,
    inlandFee,
    otherFee,
    taxFee,
  };

  return { tradingStatementFeeData };
};

/**
 * 환율 기준 날짜를 가져옵니다.
 *
 * @param shipmentDetailData - 견적 상세 데이터
 * @param invoiceData - 인보이스 데이터
 * @returns 환율 기준 날짜
 */
export const getExchangeDate = ({
  shipmentDetailData,
  invoiceData,
}: {
  shipmentDetailData: TrelloBidDetail | undefined;
  invoiceData: InvoiceData | undefined;
}): string => {
  const targetDate = (() => {
    // 저장된 명세서가 있다면 명세서 생성 시 환율 기준날짜를 리턴
    if (invoiceData) return invoiceData.exchangeRateDate;

    if (shipmentDetailData?.management.fullATA)
      return shipmentDetailData.management.fullATA;

    if (shipmentDetailData?.management.fullETA)
      return shipmentDetailData.management.fullETA;
  })();

  const isValidDate = (() => {
    if (!targetDate) {
      return false;
    }

    if (!isTodayOrBeforeToday(targetDate)) {
      // 오늘 or 이전이 아니라면 환율을 가져올 수 없다
      return false;
    }

    return true;
  })();

  // 환율을 가져오는 기준날짜가 유효하지 않은 경우는 오늘을 사용
  // targetDate는 isValidDate에서 검증된다.
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return isValidDate ? targetDate! : getAppTodayMidnight().toISOString();
};

/**
 * 거래명세서의 초기 CBM 값을 가져옵니다.
 *
 * @param shipmentDetailData - 견적 상세 데이터
 * @param invoiceData - 인보이스 데이터
 * @returns 초기 CBM 값
 */
export const getTradingStatementInitialCbm = ({
  shipmentDetailData,
  invoiceData,
}: {
  shipmentDetailData: TrelloBidDetail;
  invoiceData: InvoiceData | undefined;
}) => {
  if (invoiceData) {
    // 거래명세서가 있는 경우
    return extractCbmNumberFromCbmString(invoiceData.cbm);
  }

  if (shipmentDetailData.management.invoiceCbm) {
    // 거래명세서가 없는데 유니패스 데이터가 있는 경우
    return extractCbmNumberFromCbmString(
      shipmentDetailData.management.invoiceCbm
    );
  }

  // 거래명세서가 없는데 유니패스 데이터도 없는 경우
  return getBidDataCbmOrWeight(shipmentDetailData, "cbm");
};

/**
 * 거래명세서의 초기 톤 값을 가져옵니다.
 *
 * @param shipmentDetailData - 견적 상세 데이터
 * @param invoiceData - 인보이스 데이터
 * @returns 초기 톤 값
 */
export const getTradingStatementInitialTon = ({
  shipmentDetailData,
  invoiceData,
}: {
  shipmentDetailData: TrelloBidDetail;
  invoiceData: InvoiceData | undefined;
}) => {
  if (invoiceData) {
    // 거래명세서가 있는 경우
    return extractWeightNumberFromCbmString(
      invoiceData.cbm,
      invoiceData.freightType
    );
  }

  if (shipmentDetailData.management.invoiceCbm) {
    // 거래명세서가 없는데 유니패스 데이터가 있는 경우
    return extractWeightNumberFromCbmString(
      shipmentDetailData.management.invoiceCbm,
      shipmentDetailData.freightType
    );
  }

  // 거래명세서가 없는데 유니패스 데이터도 없는 경우
  return getBidDataCbmOrWeight(shipmentDetailData, "weight");
};

/**
 * 거래명세서의 초기 패키지 값을 가져옵니다.
 *
 * @param shipmentDetailData - 견적 상세 데이터
 * @param invoiceData - 인보이스 데이터
 * @returns 초기 패키지 값
 */
export const getTradingStatementInitialPackageValue = ({
  shipmentDetailData,
  invoiceData,
}: {
  shipmentDetailData: TrelloBidDetail;
  invoiceData: InvoiceData | undefined;
}) => {
  if (invoiceData?.package) {
    // 거래명세서가 있고 package 정보가 있는 경우
    return invoiceData.package;
  }

  if (shipmentDetailData.management.package) {
    // 거래명세서가 없지만 shipmentDetailData에 package 정보가 있는 경우
    return shipmentDetailData.management.package;
  }

  // 둘 다 없는 경우
  return "";
};

/**
 * 환율 기준 날짜를 바꾸거나 직접 수정으로 기존 환율과 달라졌는지 확인합니다.
 *
 * @param currency - 통화
 * @param defaultExchangeRateList - 기본 환율 리스트
 * @param invoiceState - 인보이스 상태
 * @returns 환율이 변경되었는지 여부
 */
export const checkExchangeModification = ({
  currency,
  defaultExchangeRateList,
  invoiceState,
}: {
  currency: Currency;
  defaultExchangeRateList: ExchangeRate[];
  invoiceState: ApplyBidFormData;
}) => {
  const defaultExchangeRate = defaultExchangeRateList.find((exchange) => {
    return exchange.currency === currency;
  })?.rate;

  const invoiceExchangeRate = invoiceState.invoiceExchangeRateList.find(
    (exchange) => {
      return exchange.currency === currency;
    }
  )?.rate;

  return defaultExchangeRate !== invoiceExchangeRate;
};

/**
 * 거래명세서 단일 항목에서 사용하는 통화를 추출합니다.
 *
 * @param feeDataList - 운임 항목 리스트
 * @returns 사용된 통화 리스트
 */
const getCurrencyListInFeeDataList = (feeDataList: ApplyBidFeeData[]) => {
  if (!feeDataList) {
    return [];
  }
  const currencyArray = feeDataList.map((n: ApplyBidFeeData) => {
    return n.currency;
  });
  return Array.from(new Set(currencyArray));
};

/**
 * 거래명세서의 항목에서 사용하는 통화를 모으고 중복 제거를 한 배열을 리턴합니다.
 *
 * @param invoiceState - 인보이스 상태
 * @returns 사용된 통화 리스트
 */
const getCurrencyListInAllFeeDataList = (invoiceState: ApplyBidFormData) => {
  const freightCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.freightFeeData
  );
  const domesticCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.domesticFeeData
  );
  const localFeeCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.localFeeData
  );
  const inlandFeeCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.inlandFeeData
  );

  const otherFeeCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.otherFeeData
  );

  const taxFeeCurrencyArr = getCurrencyListInFeeDataList(
    invoiceState.taxFeeData
  );

  const tradingStatementCurrencyArr = [
    ...freightCurrencyArr,
    ...domesticCurrencyArr,
    ...localFeeCurrencyArr,
    ...inlandFeeCurrencyArr,
    ...otherFeeCurrencyArr,
    ...taxFeeCurrencyArr,
  ];

  // 중복을 제거
  return Array.from(new Set(tradingStatementCurrencyArr));
};

/**
 * 거래명세서에서 사용하는 통화를 추출합니다.
 *
 * @param invoiceState - 인보이스 상태
 * @param exchangeData - 환율 데이터 리스트
 * @returns 사용된 환율 리스트
 */
export const extractUsedExchangeRateListFromTradingStatement = (
  invoiceState: ApplyBidFormData,
  exchangeData: ExchangeRate[]
) => {
  const currencyListInAllFeeDataList =
    getCurrencyListInAllFeeDataList(invoiceState);

  const tradingStatementExchangeData: ExchangeRate[] = [];

  exchangeData.forEach((v: ExchangeRate) => {
    for (let i = 0; i < currencyListInAllFeeDataList.length; i += 1) {
      if (currencyListInAllFeeDataList[i] === v.currency) {
        tradingStatementExchangeData.push(v);
      } else if (
        currencyListInAllFeeDataList[i] === "CNY" &&
        v.currency === "CNH"
      ) {
        tradingStatementExchangeData.push(v);
      }
    }
  });

  return tradingStatementExchangeData;
};

/**
 * amount를 소숫점 세자리까지 반올림합니다.
 *
 * @param amount - 금액
 * @returns 반올림된 금액
 */
const roundAmountToThreeDecimals = (amount: number) => {
  return Math.round(amount * 1000) / 1000;
};

/**
 * 견적단위에 따라 수량을 재계산합니다.
 *
 * @param feeData - 운임 항목 데이터
 * @param shipmentDetailData - 견적 상세 데이터
 * @param cbm - CBM 값
 * @param ton - 톤 값
 * @param directAirRton - 직접 입력한 RTon 값
 * @returns 재계산된 수량
 */
const calculateAmountByItemUnitMeasurement = ({
  feeData,
  shipmentDetailData,
  cbm,
  ton,
  directAirRton,
}: {
  feeData: TradingStatementElem;
  shipmentDetailData: TrelloBidDetail;
  cbm: number;
  ton: number;
  directAirRton: number;
}) => {
  // 거래명세서에서는 rton이 수량(amount)으로 들어가기 때문에 계산해줌.
  const amount = calculateRTonForTradingStatement({
    shipmentDetailData,
    cbm,
    ton,
    directAirRton,
  });

  if (feeData.itemUnitMeasurement === "R.TON") {
    return amount < 1 ? 1 : roundAmountToThreeDecimals(amount);
  }

  if (feeData.itemUnitMeasurement === "C/W") {
    return amount;
  }

  if (feeData.itemUnitMeasurement === "0.1 R.TON") {
    return Math.round(amount * 10);
  }

  if (feeData.itemUnitMeasurement === "B/L") {
    return 1;
  }

  return feeData.amount ?? 0;
};

/**
 * 단위에다가 환율을 곱한 값을 계산합니다.
 *
 * @param exchangeDataList - 환율 데이터 리스트
 * @param row - 운임 항목 데이터
 * @returns 환율이 적용된 단가
 */
const calculateUnitPriceWithExchangeRate = (
  exchangeDataList: ExchangeRate[],
  row: TradingStatementElem
) => {
  const exchangeData = exchangeDataList.find((v) => {
    return (
      v.currency === row.currency ||
      (v.currency === "CNY" && row.currency === "CNY")
    );
  });

  if (row.currency === "KRW" || !exchangeData) {
    return row.unitPrice;
  }

  return multiplyByBigNumber(row.unitPrice, exchangeData.rate);
};

/**
 * 업데이트된 항목 가격을 가져옵니다.
 *
 * @param feeData - 운임 항목 데이터
 * @param amount - 수량
 * @param exchangeRateList - 환율 리스트
 * @returns 업데이트된 항목 가격
 */
const getUpdatedItemPrice = ({
  feeData,
  amount,
  exchangeRateList,
}: {
  feeData: TradingStatementElem;
  amount: number;
  exchangeRateList: ExchangeRate[];
}) => {
  const unitPriceWithExchangeRate = calculateUnitPriceWithExchangeRate(
    exchangeRateList,
    feeData
  );
  if (feeData.itemUnitMeasurement === "R.TON") {
    return amount < 1
      ? Math.ceil(unitPriceWithExchangeRate)
      : Math.ceil(multiplyByBigNumber(unitPriceWithExchangeRate, amount));
  }

  if (feeData.itemUnitMeasurement === "0.1 R.TON") {
    return Math.ceil(
      multiplyByBigNumber(unitPriceWithExchangeRate, Math.round(amount))
    );
  }

  if (feeData.itemUnitMeasurement === "C/W") {
    return Math.ceil(multiplyByBigNumber(unitPriceWithExchangeRate, amount));
  }

  return Math.ceil(multiplyByBigNumber(unitPriceWithExchangeRate, amount ?? 0));
};

/**
 * 재계산된 운임 항목 데이터를 가져옵니다.
 *
 * @param feeDataList - 운임 항목 리스트
 * @param cbm - CBM 값
 * @param ton - 톤 값
 * @param shipmentDetailData - 견적 상세 데이터
 * @param directAirRton - 직접 입력한 RTon 값
 * @param exchangeRateList - 환율 리스트
 * @returns 재계산된 운임 항목 리스트
 */
export const getReCalculatedFeeData = ({
  feeDataList,
  cbm,
  ton,
  shipmentDetailData,
  directAirRton,
  exchangeRateList,
}: {
  feeDataList: TradingStatementElem[];
  cbm: number;
  ton: number;
  shipmentDetailData: TrelloBidDetail;
  directAirRton: number;
  exchangeRateList: ExchangeRate[];
}) => {
  return feeDataList.map((feeData) => {
    const amount = calculateAmountByItemUnitMeasurement({
      feeData,
      shipmentDetailData,
      cbm,
      ton,
      directAirRton,
    });
    return {
      ...feeData,
      amount,
      itemPrice: getUpdatedItemPrice({
        feeData,
        amount,
        exchangeRateList,
      }),
    };
  });
};

/**
 * RTon 문자열에서 숫자를 추출합니다.
 *
 * @param rton - RTon 문자열
 * @returns 추출된 숫자
 */
export function extractNumberFromRtonString(rton: string) {
  // 정규식을 사용하여 문자열에서 숫자만 추출한다.
  const numericString = rton.match(/\d+(\.\d+)?/)?.[0] || "";

  // 숫자 문자열을 변환
  const number = Number(numericString);

  return isNaN(number) ? 0 : number;
}
